<template>
    <div v-if="myEvents.length && getCurrentProfileType() === 'fan'" class="request-list">
        <EventBox
            v-for="event in myEvents"
            :key="event.id"
            :event="event"
            @click="$router.push(`/events/event/${event.id}`)"
        />
        <!-- Temp fix -->
        <div class="padding-bottom-box"></div>
    </div>

    <div v-else class="request-list-empty">
        <img src="@/assets/mirrored_egg.svg" />

        <LoadingEllipsis v-if="$apollo.queries.myEvents.loading">
            <p :style="{ display: 'inline-block' }" class="font-weight-medium">Fetching events</p>
        </LoadingEllipsis>
        <p v-else class="font-weight-medium">You have no outgoing requests</p>
    </div>
</template>

<script>
import EventBox from './EventBox'
import { MY_EVENTS } from '@/graphql/queries/event'

export default {
    name: 'OutgoingRequests',
    components: {
        EventBox
    },
    inject: ['getMyCurrentProfile', 'getCurrentProfileType'],
    apollo: {
        myEvents: {
            query: MY_EVENTS,
            update({ myEvents }) {
                return myEvents.sort((a, b) => new Date(a.eventStart) - new Date(b.eventStart))
            },
            fetchPolicy: 'network-only'
        }
    },
    data() {
        return {
            myEvents: []
        }
    }
}
</script>

<style lang="scss" scoped>
.request-list {
    padding: 1rem;
    // overflow: ;
    display: grid;
    align-content: start;
    row-gap: 1rem;
    overflow: auto;
    .padding-bottom-box {
        height: 6rem;
    }
    // min-height: 0;
    // height: 200px;
}

.request-list-empty {
    display: grid;
    place-content: center;
    justify-items: center;
    flex: 1;
    // height: 100%;
    padding: 0.5rem;
    row-gap: 0.5rem;
    // padding-bottom: 120px;
}
</style>
