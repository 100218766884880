export const eventStatusToColor = (status) => {
    switch (status) {
        case 'PENDING':
            return 'gray'
        case 'IN_PROGRESS':
            return 'yellow'
        case 'COMPLETE':
            return 'green'
        default:
            throw new Error('Unrecognized status')
    }
}

export const eventRequestStatusToColor = (status) => {
    switch (status) {
        case 'PENDING':
            return 'yellow'
        case 'ACCEPTED':
            return 'green'
        case 'DECLINED':
            return 'red'
        case 'CANCELED':
            return 'red'
        default:
            throw new Error('Unrecognized status')
    }
}
