<template>
    <Modal>
        <div class="payment-modal" @click.stop>
            <div class="payment-modal__header">
                <div class="payment-details">
                    <div class="payment-details__what">
                        <div class="smaller">Event</div>
                        <div class="value">{{ event.name }}</div>
                    </div>
                    <div class="payment-details__where">
                        <div class="smaller">Place</div>
                        <div class="value">{{ event.address.place }}</div>
                    </div>
                    <div class="payment-details__when">
                        <div class="smaller">Date</div>
                        <div class="value">{{ date }}</div>
                    </div>
                    <div class="payment-details__time">
                        <div class="smaller">Time</div>
                        <div class="value">{{ time }}</div>
                    </div>
                    <div class="payment-details__price">
                        <div class="smaller">Price</div>
                        <div class="value">kr. {{ event.totalPrice }}</div>
                    </div>
                </div>
            </div>
            <div class="payment-modal__body">
                <div class="payment-modal__terms">
                    <h5 class="font-weight-bold">Terms and conditions</h5>
                    <small
                        >As Buyer, I confirm by going to "Go to payment", that the above Event with
                        Date/Time, Place and Fee has been agreed between Buyer and Seller /
                        Artist.</small
                    >
                </div>
                <form class="gb-form payment-modal__form" @submit.prevent="handleSubmit">
                    <div class="gb-form gb-form__fieldset">
                        <div class="checkbox-form">
                            <Checkbox
                                id="checkbox"
                                v-model="checked"
                                label="I agree to the terms and conditions"
                                small
                            />
                        </div>
                    </div>
                    <div class="gb-form gb-form__fieldset">
                        <StripeCheckout
                            ref="checkoutRef"
                            :pk="publishableKey"
                            :session-id="sessionId"
                        />
                        <p v-if="error" class="text--red" :style="{ textAlign: 'center' }">
                            Unable to create payment session
                        </p>
                        <Button
                            id="payment-button"
                            type="submit"
                            label="Go to payment"
                            primary
                            :disabled="sessionId === null || !checked"
                            @click.stop="checkout"
                        />
                        <Button id="cancel-button" label="Cancel" @click.stop="closeModal" />
                    </div>
                </form>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal'
import Checkbox from '@/components/Checkbox'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { formatDate, formatTime } from '@/utils/dateUtils.js'
import gql from 'graphql-tag'
const eventKeys = ['eventStart', 'eventEnd', 'address', 'name', 'totalPrice']
export default {
    name: 'PaymentModal',
    components: {
        Modal,
        Checkbox,
        StripeCheckout
    },
    props: {
        event: {
            type: Object,
            required: true,
            validator(event) {
                return eventKeys.every((key) => key in event)
            }
        }
    },
    emits: ['close', 'confirm'],
    data() {
        return {
            checked: false,
            publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
            sessionId: null,
            error: false
        }
    },
    computed: {
        date() {
            return formatDate(new Date(this.event.eventStart))
        },
        time() {
            return `${formatTime(new Date(this.event.eventStart))} - ${formatTime(
                new Date(this.event.eventEnd)
            )}`
        }
    },
    async created() {
        try {
            const { data } = await this.$apollo.mutate({
                mutation: gql`
                    mutation CreatePayment($eventID: ID!) {
                        createEventPayment(eventID: $eventID) {
                            id
                            stripeID
                        }
                    }
                `,
                variables: {
                    eventID: this.event.id
                }
            })
            const {
                createEventPayment: { stripeID }
            } = data
            this.sessionId = stripeID
        } catch (error) {
            this.error = true
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        handleSubmit() {
            this.$emit('confirm')
        },
        checkout() {
            this.$refs.checkoutRef.redirectToCheckout()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.payment-modal {
    background: white;
    width: calc(100vw - 2rem);
    margin: 3rem 1rem 2rem;
    height: calc(100vh - 5rem);
    border-radius: 1.5rem;
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    overflow: auto;
    &__header {
        grid-row: 1 / 2;
        background-color: $primary;
        padding: 1.5rem;
    }
    &__body {
        width: 100%;
        grid-row: 2 / 4;
        background-color: $light;
        display: grid;
        grid-template-rows: repeat(2, 1fr);
    }

    &__terms {
        background-color: white;
        overflow: auto;
        padding: 1rem;
        margin: 1rem 0.5rem;
        line-height: 16px;
        min-height: 130px;
        h5 {
            margin-top: 0;
            margin-bottom: 0.5rem;
            font-size: 12px;
        }
    }

    &__form {
        row-gap: 0.5rem;
        background: $light;
    }
}

.payment-details {
    color: $beige;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    &__what {
        grid-row: 1 / 2;
    }
    &__where {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }
    &__when {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
    }
    &__time {
        grid-row: 3 / 4;
        grid-column: 2 / 3;
    }
    &__price {
        grid-row: 4 / 5;
    }
}
</style>
