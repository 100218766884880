<template>
    <div class="checkbox" :class="{ right }">
        <input
            :checked="value"
            :disabled="disabled"
            type="checkbox"
            @change="$emit('input', $event.target.checked)"
        />
        <label :class="{ small: small }">{{ label }}</label>
    </div>
</template>
<script>
export default {
    name: 'Checkbox',
    props: {
        right: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            default: false,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    created() {
        // Preload asset to prevent slow load
        require('@/assets/checkbox_checked.svg')
    }
}
</script>
<style lang="scss" scoped>
@import '@/sass/variables';

.checkbox {
    &.right {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 1rem;
    // background: white;

    label {
        font-size: 18px;
        &.small {
            font-size: 14px;
        }
        // justify-self: start;
    }

    input[type='checkbox'] {
        height: 30px;
        width: 30px;
        margin: 0;
        appearance: none;
        padding: 0;
        border-radius: 2px;
        border: 0;
        background: white;
        outline: 0;
        box-shadow: 0.1rem 0.1rem 5px 1px rgba(0, 0, 0, 0.1);
        &:hover {
            cursor: pointer;
        }
    }

    input[type='checkbox']:checked {
        background: url('~@/assets/checkbox_checked.svg');
    }

    // & input[type='checkbox'] {
    //     appearance: none;
    //     position: relative;
    //     height: 30px;
    //     width: 30px;
    //     top: 18px;

    //     background: $white;
    //     border: 1px solid $white;
    //     padding: 0;
    //     border-radius: 2px;
    // }

    // & input[type='checkbox']:hover {
    //     border: 1px solid $primary;
    // }

    // & input[type='checkbox']:checked::before {
    //     content: '';
    //     position: absolute;
    //     top: -1px;
    //     left: -1px;
    //     width: 100%;
    //     height: 100%;

    //     background: $primary;
    //     border: 1px solid $primary;
    //     border-radius: 3px;
    // }

    // & input[type='checkbox']:checked::after {
    //     content: '';
    //     position: absolute;
    //     left: 4px;
    //     top: 13px;
    //     background: white;
    //     width: 2px;
    //     height: 2px;
    //     box-shadow: 0 0 0 white, 2px 0 0 white, 4px 0 0 white, 6px 0 0 white,
    //         8px 0 0 white, 8px -2px 0 white, 8px -4px 0 white, 8px -6px 0 white,
    //         8px -8px 0 white, 8px -10px 0 white, 8px -12px 0 white,
    //         8px -14px 0 white, 8px -16px 0 white;
    //     transform: rotate(45deg);
    // }

    // & input[type='checkbox']:checked + .input-label {
    //     font-weight: bold;
    // }

    // & .input-label {
    //     display: inline-block;
    //     margin-left: 15px;
    //     font-size: 18px;
    // }
}
</style>
