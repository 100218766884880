import gql from 'graphql-tag'

export const UPDATE_USER_EVENT = gql`
    mutation UpdateUserEvent($input: updateEventInput!) {
        updateUserEvent(input: $input) {
            ... on ResponseError {
                errors {
                    message
                }
            }
            ... on updateEventPayload {
                event {
                    id
                    name
                    description
                    location
                }
            }
        }
    }
`

export const CREATE_USER_EVENT = gql`
    mutation CreateUserEvent($input: createEventInput!) {
        createUserEvent(input: $input) {
            ... on ResponseError {
                errors {
                    message
                }
            }
            ... on createEventPayload {
                event {
                    id
                    name
                    description
                }
            }
        }
    }
`
