<template>
    <div class="event-box" @click="$emit('click')">
        <div class="event-box__header" :class="{ 'event-box--primary': isComplete }">
            <div class="event-box__left">
                <h3 class="event-box__title font-weight-medium">
                    {{ event.name }}
                </h3>
                <small>
                    {{ event.locationText }}
                    •
                    {{ startDate }}
                </small>
            </div>
            <div class="event-box__right">
                <Badge
                    v-if="!isComplete"
                    :label="event.statusText.toLowerCase()"
                    :color="statusBadgeColor"
                    size="small"
                />
                <Badge
                    v-else-if="event.paymentStatus === 'COMPLETE'"
                    label="Paid"
                    color="green"
                    size="small"
                />
            </div>
        </div>
        <div
            v-if="event.venue"
            class="event-box__accepted"
            :class="{ 'event-box--primary': isComplete }"
        >
            <img :src="event.venue.avatar" class="avatar-sm" />
            <div :style="{ display: 'grid', alignContent: 'center' }">
                <p class="font-weight-medium">{{ event.venue.name }}</p>
                <small>{{ event.venue.__typename }}</small>
            </div>
            <Badge v-if="!isComplete" label="Accepted" size="small" color="green" />
        </div>
        <div
            v-if="event.artist"
            class="event-box__accepted"
            :class="{ 'event-box--primary': isComplete }"
        >
            <img :src="event.artist.avatar" class="avatar-sm" />
            <div :style="{ display: 'grid', alignContent: 'center' }">
                <p class="font-weight-medium">{{ event.artist.name }}</p>
                <small>{{ event.artist.__typename }}</small>
            </div>
            <Badge v-if="!isComplete" label="Accepted" size="small" color="green" />
        </div>
        <div v-if="isComplete" class="event-box__footer">
            <div class="grid grid--cols-2">
                <Button
                    v-if="event.paymentStatus !== 'COMPLETE'"
                    label="Pay"
                    size="small"
                    square
                    primary
                    :disabled="!event || !Object.keys(event).length"
                    @click.stop="showPaymentModal"
                />
                <!-- <Button label="Start ticket sale" size="small" square /> -->
            </div>
        </div>
        <div v-else-if="event.venue || event.artist" class="event-box__footer">
            <ApolloMutation
                v-slot="{ mutate, loading, error }"
                :mutation="
                    (gql) => gql`
                        mutation UpdateEventStatus($input: EventStatusInput!) {
                            updateEventStatus(input: $input) {
                                id
                                status
                            }
                        }
                    `
                "
                :variables="{
                    input: {
                        id: event.id,
                        status: 'COMPLETE'
                    }
                }"
            >
                <Button
                    v-if="!loading"
                    label="Close request"
                    square
                    primary
                    size="small"
                    @click.stop="mutate"
                />
                <Button
                    v-else-if="error"
                    label="Error"
                    square
                    primary
                    size="small"
                    :disabled="true"
                />

                <Button v-else label="Please wait" square primary size="small" />
            </ApolloMutation>
        </div>
        <PaymentModal
            v-if="isPaymentModalVisible"
            :event="event"
            @close="closePaymentModal"
            @confirm="closePaymentModal"
        />
    </div>
</template>

<script>
import Badge from '@/components/Badge'
import { format } from 'date-fns'
import { UPDATE_USER_EVENT } from '@/graphql/mutations/event.js'
import { eventStatusToColor } from '@/utils/statusUtils'
import PaymentModal from '@/components/modals/PaymentModal'

export default {
    name: 'EventBox',
    components: {
        Badge,
        PaymentModal
    },
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    emits: ['click'],
    data() {
        return {
            updateUserEventMutation: UPDATE_USER_EVENT,
            isPaymentModalVisible: false
        }
    },
    computed: {
        startDate() {
            return format(new Date(this.event.eventStart), 'dd/MM/yyyy')
        },
        isComplete() {
            // return false
            return this.event.status === 'COMPLETE'
        },
        statusBadgeColor() {
            return eventStatusToColor(this.event.status)
        }
    },
    methods: {
        showPaymentModal() {
            return (this.isPaymentModalVisible = true)
        },
        closePaymentModal() {
            return (this.isPaymentModalVisible = false)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
.grid {
    display: grid;
    &--cols-2 {
        grid-template-columns: repeat(2, minmax(auto, auto));
        column-gap: 0.5rem;
    }
}
.event-box {
    background-color: white;
    cursor: pointer;
    & > *:not(:first-child) {
        border-top: 2px solid $light;
    }
    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__header {
        padding: 1rem 0.5rem;
        display: grid;
        grid-template-columns: 1fr auto;
    }
    &__left {
        align-self: center;
        display: grid;
        padding: 0.5rem;
        row-gap: 0.5rem;
    }

    &__right {
        align-self: start;
    }

    &__accepted {
        display: grid;
        grid-template-columns: auto 1fr auto;
        column-gap: 1rem;
        align-items: center;
        padding: 0.5rem 1rem;
    }

    &__footer {
        padding: 1rem 0.5rem;
        display: grid;
        place-items: center;
    }

    &--primary {
        background-color: $primary;
        color: $beige;
        border-radius: 5px;
    }
}
</style>
